<template>
  <div id="select-occasion" class="mt-2">
    <b-overlay :show="loader" rounded="sm">
      <div class="container mb-3 ">
        <center>
          <h3 for="">
            <strong class="text-danger my-3"></strong>WHAT IS YOUR RELATIONSHIP
            TO THIS PERSON?
          </h3>
          <span v-if="button" class="select-relation-error-text text-danger"
            >*Please select a relation</span
          >
        </center>
        <label class="wrap my-5 col-md-12">
          <select
            name=""
            class="form-control noBorder"
            id=""
            v-model="selected_relation"
          >
            <option value="">Select Relation</option>
            <option :value="relation" v-for="relation in relations">{{
              relation.relation_name
            }}</option>
          </select>
        </label>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4 ">
              <button class="btn btn-danger col-md-6 pull-right" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "SelectRelation",
  props: ["menu"],
  data() {
    return {
      relations: [],
      selected_relation: "",
      button: false,
      loader: true,
    };
  },
  created() {
    this.getAllRelations();
  },
  methods: {
    getAllRelations() {
      this.loader = true;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/relations/getAllRelations";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          type: this.menu.label,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          if (data.relation) {
            this.relations = data.relation.relations;
          } else {
            this.relations = data.relations;
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    next() {
      if (this.selected_relation == "") {
        this.button = true;
      } else {
        this.$emit("relationToPersona", this.selected_relation);
      }
    },
    prev() {
      this.$emit("relationToTrait", this.occasion);
    },
  },
};
</script>

<style>
.select-relation-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>
