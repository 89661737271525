<template>
  <div id="layout">
    <main>
      <menu-bar></menu-bar>
      <app-content />
      <footer-bar></footer-bar>
    </main>
  </div>
</template>

<script>
// Layout components
import MenuBar from "@/views/site/blocks/MenuBar";
import FooterBar from "@/views/site/blocks/FooterBar";
import AppContent from "@/views/site/blocks/AppContent";

export default {
  name: "SiteLayout",
  data() {
    return {
      route_name: "",
    };
  },
  components: {
    MenuBar,
    FooterBar,
    AppContent,
  },
  created() {
    this.checkurl();
  },
  methods: {
    checkurl() {
      var segment_str = window.location.pathname;
      var segment_array = segment_str.split("/");
      this.route_name = segment_array[1];
    },
  },
  watch: {
    $route(to, from) {
      let split_route = to.path.split("/");
      this.route_name = split_route[1];
    },
  },
};
// Layout components
</script>
