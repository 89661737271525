<template>
  <div id="select-preference" class="mt-2">
    <b-overlay :show="loader" rounded="sm">
      <div class="container mb-3 ">
        <center>
          <h3 for="">
            <strong class="text-danger my-3"></strong>PREFERENCES FOR PET
          </h3>
          <span v-if="button" class="pet-select-occasion-error-text text-danger"
            >*Please select an Occasion</span
          >
        </center>
        <label class="wrap my-5 col-md-12">
          <select
            name=""
            class="form-control noBorder"
            id=""
            v-model="selected_preference"
          >
            <option value="">--Select Preferences--</option>
            <option :value="preference" v-for="preference in preferences">{{
              preference.keyword
            }}</option>
          </select>
        </label>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 ">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger float-right col-md-6" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "AddPreference",
  data() {
    return {
      loader: false,
      preferences: [],
      selected_preference: "",
      button: false,
    };
  },
  created() {
    this.getAllPreferences();
  },
  methods: {
    getAllPreferences() {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/pet/getAllPreferences";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.preferences = data.preferences;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    next() {
      this.loader = true;
      this.$emit("preferenceToPrice", this.selected_preference);
    },
    prev() {
      this.$emit("preferenceToImage");
    },
  },
};
</script>

<style>
.pet-select-occasion-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>
