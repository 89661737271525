<template>
  <div id="search-view">
    <div>
      <div class="row">
        <div class="col-md-12 ">
          <vue-autosuggest
            :suggestions="filteredOptions"
            :input-props="{
              id: 'autosuggest__input',
              placeholder: 'Search...',
            }"
            @input="onInputChange"
            @selected="onSelected"
          >
            <template slot-scope="{ suggestion }">
              <span class="my-suggestion-item">{{ suggestion.item }}</span>
            </template>
          </vue-autosuggest>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
export default {
  name: "SearchView",
  data() {
    return {
      search_word: null,
      search: null,
      search_array: [
        {
          data: [
            "Frodo",
            "Samwise",
            "Gandalf",
            "Galadriel",
            "Faramir",
            "Éowyn",
          ],
        },
      ],
      search_options: [],
      selected: "",
      query: "",
      menus: null,
      suggestions: [
        {
          data: [],
        },
      ],
    };
  },
  components: {
    VueAutosuggest,
  },
  computed: {
    filteredOptions() {
      return [
        {
          data: this.suggestions[0].data.filter((option) => {
            return option.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
          }),
        },
      ];
    },
  },
  methods: {
    onInputChange(text) {
      this.search = text;
      this.getMenuList(this.search);
    },
    getMenuList(text) {
      var fetch_url = process.env.VUE_APP_URL + "customer/menu/searchMenus";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          search: text,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.menus = data.menu;
          this.search_options = [];
          this.menus.forEach((menu_data, key) => {
            this.search_options.push(menu_data.label);
          });
          this.suggestions[0].data = this.search_options;
          this.video_details = data;
          this.vid_loader = false;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    onSelected(item) {
      if (item == null) {
        return;
      }
      this.search = item.item;
      console.log("search keyword" + this.search);
      this.keword_search();
    },
    keword_search() {
      this.$store.state.pet_id = null;
      this.$store.state.occasion_id = null;
      this.search_load = true;
      var fetch_url =
        process.env.VUE_APP_URL + "customer/search/keyword_search";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          keyword: this.search,
          type: "keyword",
          category: this.selected_category,
          page: 1,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.search_load = false;
          var products = data.response;

          const id = Math.random()
            .toString(36)
            .slice(2);
          this.$store.state.isGiftCard = false;
          this.$store.state.keyword = this.search_word;
          this.$store.state.category = this.selected_category;
          this.$store.state.products = data.response.item;
          this.$store.state.totalPages = data.response.pagination.max_page;

          this.$store.state.page = 1;
          this.$router.replace({
            name: "SearchView",
            params: {
              id,
            },
          });
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
  },
};
</script>
<style>
#autosuggest__input {
  outline: none;
  position: relative;
  display: block;
  font-family: "ITC Avant Garde Gothic Book Condensed" !important;
  font-size: 14px !important;
  border: 1px solid #616161;
  padding: 8px !important;
  width: 510px !important;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.autosuggest__results ul li {
  list-style: none;
  padding: 8px 5px !important;
  cursor: pointer;
}
.autosuggest__results ul li:hover {
  background-color: #f8f9fa !important;
}
.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  max-height: 200px;
}
</style>
