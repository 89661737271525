<template>
  <div id="select-continue" class="mt-2">
    <b-overlay :show="loader">
      <div class="container mb-3 ">
        <div class="modal-inner  d-flex border justify-content-center bg-light">
          <div class="row">
            <h4 class="my-5 mx-5">
              {{ selected_friend.contact_name }} was already saved in our system
            </h4>
          </div>
        </div>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 ">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger float-right col-md-6" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "SelectContinue",
  props: ["selected_friend", "menu"],
  data() {
    return {
      loader: false,
    };
  },
  methods: {
    next() {
      this.loader = true;
      if (this.menu == "under_fifty") {
        this.loader = true;
        this.$emit("fiftySubmit", null);
      } else {
        this.loader = true;

        this.$emit("showPrceRange", true);
      }
    },
    prev() {
      this.$emit("SelcetContactToAddfriend");
    },
  },
};
</script>
